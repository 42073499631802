import React, { useMemo } from 'react';
import Chip from 'components/ui-components-v2/Chip';
import Icon from 'components/ui-components-v2/Icon';
import FeedHelpers from '../../../data/FeedManagementHelpers';

import '../styles/mutations-chip.scss';

const FeedManagementActivityMutationsChip = ({ mutationType }) => {
    const label = useMemo(() => FeedHelpers.getMutationTypeLabel(mutationType), [mutationType]);

    const icon = (() => {
        switch (mutationType) {
            case 0:
                return <Icon fontSize="small">add_circle_outline</Icon>;
            case 1:
                return <Icon fontSize="small">refresh</Icon>;
            case 2:
                return <Icon fontSize="small">clear</Icon>;
            default:
                return '';
        }
    })();

    const color = (() => {
        switch (mutationType) {
            case 0:
                return 'success';
            case 1:
                return 'warning';
            case 2:
                return 'error';
        }
    })();

    return (
        <Chip
            color={color}
            size="small"
            variant="outlined"
            label={
                <div className="feed-management-activity-mutations-chip-content">
                    {icon} {label}
                </div>
            }
        />
    );
};

export default FeedManagementActivityMutationsChip;
