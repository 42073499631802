import React, { useMemo } from 'react';
import classNames from 'classnames';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import FeedManagementActivityMutationsChip from './mutations-chip';
import DataItemKeyDisplay from '../../DataItemKeyDisplay';

import '../styles/mutations-row.scss';

/**
 * From the mutation, we want to show the new values if present. In case of a delete show the old.
 * @param {object} mutation
 * @returns
 */
const getDisplayData = (mutation) => {
    return mutation.newItem || mutation.oldItem;
};

const FeedManagementActivityMutationsRow = ({ mutation, displayStructure, openMutation }) => {
    const displayData = useMemo(() => getDisplayData(mutation), [mutation]);
    return (
        <TableRow hover className="feed-management-activity-mutations-row" onClick={() => openMutation(mutation)}>
            <TableCell className="feed-management-activity-mutations-row__status">
                <FeedManagementActivityMutationsChip mutationType={mutation.mutationType} />
            </TableCell>
            {displayStructure.map((item) => (
                <TableCell
                    key={`${mutation.id}-${item.key}`}
                    className={classNames('feed-management-activity-mutations-row__cell', {
                        'feed-management-activity-mutations-row__cell--one': displayStructure.length === 1,
                        'feed-management-activity-mutations-row__cell--two': displayStructure.length === 2,
                        'feed-management-activity-mutations-row__cell--deleted': mutation.mutationType === 2
                    })}>
                    {displayData && <DataItemKeyDisplay type={item.type} value={displayData[item.key]} />}
                </TableCell>
            ))}
        </TableRow>
    );
};

export default FeedManagementActivityMutationsRow;
