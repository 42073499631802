import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import '../styles/detail-section.scss';

const FeedManagementActivityDetailSection = ({ children }) => {
    return (
        <div className="feed-management-activity-detail-section">
            <div className="feed-management-activity-detail-section__title">
                <div className="feed-management-activity-detail-section__icon">
                    <Icon>list</Icon>
                </div>
                Summary
            </div>
            {children && <div className="feed-management-activity-detail-section__content">{children}</div>}
        </div>
    );
};

export default FeedManagementActivityDetailSection;
