import React, { useMemo } from 'react';
import classNames from 'classnames';
import FeedHelpers from '../../../data/FeedManagementHelpers';
import { ItemPreviewEntry } from '../../../feed-management/ItemPreview';
import Dialog from '../../../ui-components/Dialog';

import '../styles/mutations-dialog.scss';

/**
 * Build an array of changed fields for this mutation.
 * @param {object} mutation
 * @returns {array} changed field keys
 */
const getChangedFields = (mutation) => {
    switch (mutation.mutationType) {
        case 0:
            return Object.keys(mutation.newItem);
        case 1:
            return Object.keys(mutation.changedFields);
        case 2:
            return Object.keys(mutation.oldItem);
    }
};

const FeedManagementActivityMutationsDialog = ({ mutation, displayTypes, onClose }) => {
    const newData = useMemo(() => (mutation.mutationType === 2 ? null : FeedHelpers.getParsedItem(mutation.newItem)));
    const oldData = useMemo(() => (mutation.mutationType === 0 ? null : FeedHelpers.getParsedItem(mutation.oldItem)));
    const changedFields = useMemo(() => getChangedFields(mutation), [mutation]);

    return (
        <Dialog fixedHeightBig title={FeedHelpers.getMutationTypeLabel(mutation.mutationType)} onClose={onClose} open>
            <div
                className={classNames('feed-management-activity-mutations-dialog', {
                    'feed-management-activity-mutations-dialog--updated': mutation.mutationType === 1
                })}>
                {oldData && (
                    <div
                        className={classNames('feed-management-activity-mutations-dialog__column', {
                            'feed-management-activity-mutations-dialog__column--half': mutation.mutationType === 1
                        })}>
                        {Object.keys(oldData).map((key) => (
                            <ItemPreviewEntry
                                key={key}
                                entryKey={key}
                                entryValue={oldData[key]}
                                displayTypes={displayTypes}
                                markAsOld={changedFields.includes(key)}
                            />
                        ))}
                    </div>
                )}
                {newData && (
                    <div
                        className={classNames('feed-management-activity-mutations-dialog__column', {
                            'feed-management-activity-mutations-dialog__column--half': mutation.mutationType === 1
                        })}>
                        {Object.keys(newData).map((key) => (
                            <ItemPreviewEntry
                                key={key}
                                entryKey={key}
                                entryValue={newData[key]}
                                displayTypes={displayTypes}
                                markAsNew={changedFields.includes(key)}
                            />
                        ))}
                    </div>
                )}
            </div>
        </Dialog>
    );
};

export default FeedManagementActivityMutationsDialog;
