import React, { useState, useEffect, useMemo } from 'react';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Loader from 'components/ui-components/Loader';
import FeedHelpers, { FeedRequest } from 'components/data/FeedManagementHelpers/';
import { withComponentStore } from 'components/data/ComponentStore';
import AlertBox from 'components/ui-components/AlertBox';
import Translation from 'components/data/Translation';
import FeedManagementActivityMutationsRow from './mutations-row';
import FeedManagementActivityMutationsDialog from './mutations-dialog';

import '../styles/mutations.scss';

const getDisplayStructure = (dataSet) => {
    const completeStructure = FeedHelpers.getStructure(dataSet);
    return completeStructure.filter((column) => !column.hideColumn).slice(0, 3);
};

const FeedManagementActivityMutations = ({ operationId, dataSet, totalMutations }) => {
    const [loading, setLoading] = useState(true);
    const [waiting, setWaiting] = useState(false);
    const [pageToken, setPageToken] = useState(0);
    const [tablePage, setTablePage] = useState(0);
    const [tableRows, setTableRows] = useState(10);
    const [hasMore, setHasMore] = useState(true);
    const [mutations, setMutations] = useState([]);
    const [openMutation, setOpenMutation] = useState(null);
    const displayStructure = useMemo(() => getDisplayStructure(dataSet), [dataSet]);
    const displayTypes = useMemo(() => FeedHelpers.getDisplayTypes(displayStructure), [displayStructure]);

    /**
     * Get mutations from te API.
     * @param {string} operationId
     * @param {number} pageToken
     */
    const getMutations = (operationId, pageToken) => {
        FeedRequest.get(`/mutations/${operationId}`, { params: { pageToken } }).then((response) => {
            if (response.data && response.data.data) {
                setHasMore(response.data.data.length < totalMutations);
                setMutations([...mutations, ...response.data.data]);
                setPageToken(response.data.pageToken);
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        const timer = setTimeout(() => setWaiting(true), 3000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        getMutations(operationId, 0);
    }, [operationId]);

    /**
     * Determine if more mutations should be loaded and if so, load them.
     */
    const loadMore = (tablePage, tableRows) => {
        if (hasMore) {
            const needed = (tablePage + 1) * tableRows;
            if (needed > mutations.length - tableRows) getMutations(operationId, pageToken);
        }
    };

    /**
     * Handle the changing of rows per page.
     * @param {event} rows
     */
    const handleChangeRowsPerPage = (rows) => {
        setTablePage(0);
        setTableRows(rows.target.value);
        loadMore(0, rows.target.value);
    };

    /**
     * Handle changing of the page in the paginated table.
     * @param {*} _
     * @param {number} page
     */
    const handleChangePage = (_, page) => {
        setTablePage(page);
        loadMore(page, tableRows);
    };

    return (
        <React.Fragment>
            {loading && waiting && <AlertBox hideClose>{Translation.get('mutations.longTimeAlert', 'feed-management')}</AlertBox>}
            <Loader isLoading={loading}>
                <TableContainer>
                    <Table stickyHeader className="feed-management-activity-mutations__table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Action</TableCell>
                                {displayStructure.map((item) => (
                                    <TableCell key={item.key}>{item.title}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {mutations
                                .filter((m) => m.itemId)
                                .slice(tablePage * tableRows, tablePage * tableRows + tableRows)
                                .map(
                                    (m) =>
                                        m.itemId &&
                                        (m.newItem || m.oldItem) && (
                                            <FeedManagementActivityMutationsRow
                                                key={m.itemId}
                                                mutation={m}
                                                displayStructure={displayStructure}
                                                openMutation={setOpenMutation}
                                            />
                                        )
                                )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <TablePagination
                    component="div"
                    rowsPerPageOptions={[10, 25, 100]}
                    count={totalMutations}
                    rowsPerPage={tableRows}
                    page={tablePage}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                {openMutation && (
                    <FeedManagementActivityMutationsDialog
                        mutation={openMutation}
                        key={openMutation.itemId}
                        displayTypes={displayTypes}
                        onClose={() => setOpenMutation(null)}
                    />
                )}
            </Loader>
        </React.Fragment>
    );
};

export default withComponentStore(FeedManagementActivityMutations, 'FeedManager');
