import React from 'react';
import classNames from 'classnames';
import Status from '../../../ui-components/Status';
import Translation from '../../../data/Translation';

import '../styles/detail-control.scss';

/**
 * Get the title for the operation
 * @param {object} operation
 * @returns {string} title
 */
const getTitle = (operation) => {
    if (operation.status === 'done') return Translation.get('activity.status.done', 'feed-management');
    if (operation.status === 'failed') return Translation.get('activity.status.failed', 'feed-management');
    return Translation.get('activity.status.processing', 'feed-management');
};

const FeedManagementActivityDetailControl = ({ operation }) => {
    return (
        <div className="feed-management-activity-detail-control">
            <div
                className={classNames('feed-management-activity-detail-control__status', {
                    'feed-management-activity-detail-control__status--done': operation.status === 'done',
                    'feed-management-activity-detail-control__status--failed': operation.status === 'failed',
                    'feed-management-activity-detail-control__status--processing': operation.status === 'processing'
                })}>
                <Status status={operation.status} big />
            </div>
            <div className="feed-management-activity-detail-control__title">{getTitle(operation)}</div>
            <div className="feed-management-activity-detail-control__subtitle">{operation.title}</div>
        </div>
    );
};

export default FeedManagementActivityDetailControl;
