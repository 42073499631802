import React, { useState } from 'react';
import AutoPublishFormDialog from './dialog';

/** Form in a dialog to add or edit the autopublish setting of a data set */
const AutoPublishForm = ({ dataSet = {}, children }) => {
    const [showDialog, setShowDialog] = useState(false);

    /** Add the open dialog action to the child of this component
     * @param {child} data -
     */
    const childrenWithProps = (child) => {
        // checking isValidElement is the safe way and avoids a typescript error too
        const props = {
            onClick: () => {
                setShowDialog(true);
            }
        };
        if (React.isValidElement(child)) {
            return React.cloneElement(child, props);
        }
        return child;
    };

    return (
        <React.Fragment>
            {childrenWithProps(children)}
            {showDialog && <AutoPublishFormDialog dataSet={dataSet} onClose={() => setShowDialog(false)} />}
        </React.Fragment>
    );
};

export default AutoPublishForm;
