import React from 'react';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import FormFlow from 'components/ui-base/FormFlow';
import FeedHelpers, { FeedRequest } from 'components/data/FeedManagementHelpers';
import '../styles/dialog.scss';
import Translation from 'components/data/Translation';

/** Form in a dialog to add or edit autopublish settings */
const AutoPublishFormDialog = ({ dataSet = {}, onClose }) => {
    // If there is a form setup given in the props, use that one. Otherwise, use the default form setup
    const formSetup = [
        {
            items: [
                {
                    type: 'radioGroup',
                    model: 'schedule',
                    title: Translation.get('feeds.feedActions.autoPublishDialog.schedule', 'feed-management'),
                    options: FeedHelpers.feedCrons
                }
            ]
        }
    ];

    /** Edit the auto publish schedule of a data set.
     * @param {object} formData -
     */
    const handleUpdate = (formData) => {
        const payload = { ...formData };
        // If the never cron schedule is selected, force enabled to false.
        if (payload.schedule === FeedHelpers.neverCron) {
            payload.enabled = false;
        }
        return Promise.resolve(
            FeedRequest.patch(`dataset/${dataSet._id}/`, {
                autoPublish: payload
            })
                .then(() => {
                    FeedHelpers.reloadDataSet(dataSet._id);
                    onClose();
                })
                .catch((error) => {
                    SnackbarUtils.error(Translation.get('feeds.addForm.updatingFeedFailed', 'feed-management'));
                    console.error(error);
                })
        );
    };

    return (
        <div className="feed-management-autopublish-form-dialog">
            <FormFlow
                onSubmit={handleUpdate}
                data={dataSet.autoPublish}
                setup={formSetup}
                onSubmitComplete={() => {}}
                submitButtonLabel={Translation.get('feeds.feedActions.autoPublishDialog.editButton', 'feed-management')}
                saveNotification={Translation.get(
                    'feeds.feedActions.autoPublishDialog.saveNotification',
                    'feed-management'
                )}
                title={Translation.get('feeds.feedActions.autoPublishDialog.title', 'feed-management')}
                onCloseDialog={onClose}
                modalOpen
                view="modal"
            />
        </div>
    );
};

export default AutoPublishFormDialog;
