import React, { useEffect, useState } from 'react';
import moment from 'moment';
import EmptyState from 'components/ui-components-cape/EmptyState';
import Illustration from 'components/ui-components-cape/Illustration';
import FeedManagementActivityDetail from './detail';
import FeedHelpers, { FeedRequest } from '../../../data/FeedManagementHelpers/';
import { withComponentStore } from '../../../data/ComponentStore';
import Translation from '../../../data/Translation';
import Loader from '../../../ui-components/Loader';
import Sidebar from '../../../ui-base/Sidebar';
import FeedManagementActivityListMutations from './list-mutations';

import '../styles/main.scss';

/**
 * Format the operations list for use in the sidebar
 * @param {array} rawList
 * @param {number} totalLength
 * @returns {array} formatted operations list
 */
const formatList = (rawList, total) => {
    return rawList.map((item, index) => {
        return {
            id: item.id,
            status: FeedHelpers.getOperationStatus(item.status, item.results),
            title:
                index === 0
                    ? Translation.get('activity.currentVersion', 'feed-management')
                    : `${Translation.get('activity.version', 'feed-management')} ${total - index}`,
            subTitle: (
                <div className="feed-management-activity__subtitle">
                    {moment(item.startedOn).fromNow()} <FeedManagementActivityListMutations results={item.results} />
                </div>
            ),
            data: item
        };
    });
};

const FeedManagementActivity = ({ dataSet }) => {
    const [loading, setLoading] = useState(true);
    const [operations, setOperations] = useState([]);
    const [pageToken, setPageToken] = useState(0);
    const [total, setTotal] = useState(0);
    const [activeOperation, setActiveOperation] = useState();

    /**
     * Get the operations from the API.
     * @param {string} datasetId
     * @param {number} operationType
     * @param {number} pageToken
     */
    const getOperations = (datasetId, operationType, pageToken) => {
        FeedRequest.get('/operations/list', { params: { datasetId, operationType, pageToken } }).then((response) => {
            if (response.data && response.data.data) {
                setOperations([...operations, ...response.data.data]);
                setPageToken(response.data.pageToken);
                setTotal(response.data.total);
            }
            setLoading(false);
        });
    };

    useEffect(() => {
        if (dataSet) {
            getOperations(dataSet._id, 0, pageToken);
        } else {
            setLoading(false);
        }
    }, []);

    /**
     * Load the next page of operations from the API.
     */
    const showMore = () => {
        getOperations(dataSet._id, 0, pageToken);
    };

    /**
     * Find the selected operation by it's id and set it as active.
     * @param {string} id
     */
    const selectOperation = (id) => {
        const foundOperation = formatList(operations, total).find((o) => o.id === id);
        if (foundOperation) {
            setActiveOperation(foundOperation);
        }
    };

    if (!loading && !operations.length)
        return (
            <EmptyState
                size="large"
                className="feed-management-activity__empty-state"
                primaryText={Translation.get('activity.noActivities', 'feed-management')}
                illustration={<Illustration size="large" illustration="fallback" />}
            />
        );

    return (
        <Loader isLoading={loading}>
            <Sidebar
                listTitle={Translation.get('activity.listTitle', 'feed-management', { total })}
                list={formatList(operations, total)}
                mainButtonCopy={Translation.get('actions.showMore', 'common')}
                onClickMainButton={total > operations.length ? showMore : undefined}
                activeItem={activeOperation && activeOperation.id}
                onSelect={selectOperation}>
                <FeedManagementActivityDetail operation={activeOperation} />
            </Sidebar>
        </Loader>
    );
};

export default withComponentStore(FeedManagementActivity, 'FeedManager');
