import React from 'react';
import Icon from 'components/ui-components-v2/Icon';
import Chip from 'components/ui-components-v2/Chip';

import '../styles/list-mutations.scss';

const FeedManagementActivityListMutations = ({ results }) => {
    return (
        <div className="feed-management-activity-list-mutations">
            {results?.created > 0 && (
                <Chip
                    color={'success'}
                    size="small"
                    variant="outlined"
                    label={
                        <div className="feed-management-activity-list-mutations__chip-content">
                            <Icon>add_circle_outline</Icon>
                            <span>{results.created}</span>
                        </div>
                    }
                />
            )}
            {results?.updated > 0 && (
                <Chip
                    color={'warning'}
                    size="small"
                    variant="outlined"
                    label={
                        <div className="feed-management-activity-list-mutations__chip-content">
                            <Icon>refresh</Icon>
                            <span>{results.updated}</span>
                        </div>
                    }
                />
            )}
            {results?.deleted > 0 && (
                <Chip
                    color={'error'}
                    size="small"
                    variant="outlined"
                    label={
                        <div className="feed-management-activity-list-mutations__chip-content">
                            <Icon>clear</Icon>
                            <span>{results.deleted}</span>
                        </div>
                    }
                />
            )}
        </div>
    );
};

export default FeedManagementActivityListMutations;
