import React, { useState, useEffect } from 'react';
import { TabbarTabs } from 'components/ui-base/Tabbar';
import FeedManagementActivityDetailControl from './detail-control';
import FeedManagementActivityDetailSection from './detail-section';
import FeedManagementActivityDetailSectionRow from './detail-section-row';
import FeedManagementActivityMutations from './mutations';
import Translation from '../../../data/Translation';

import '../styles/detail.scss';

const getRowLabel = (key, count) => {
    switch (key) {
        case 'created':
            return Translation.get('activity.labels.created', 'feed-management', { count });
        case 'updated':
            return Translation.get('activity.labels.updated', 'feed-management', { count });
        case 'deleted':
            return Translation.get('activity.labels.deleted', 'feed-management', { count });
    }
};

const getTotalMutations = (operation) => {
    if (operation && operation.data && operation.data.results && operation.data.results.created !== undefined) {
        const total = operation.data.results.created + operation.data.results.updated + operation.data.results.deleted;
        return total;
    }
    return 0;
};

const FeedManagementActivityDetail = ({ operation }) => {
    const [view, setView] = useState('detail');
    const [totalMutations, setTotalMutations] = useState(getTotalMutations(operation));

    useEffect(() => {
        setTotalMutations(getTotalMutations(operation));
        setView('detail');
    }, [operation]);

    /**
     * Change the active view
     * @param {object} item
     */
    const changeView = (item) => {
        setView(item.key);
    };

    if (!operation) return null;
    return (
        <React.Fragment>
            <FeedManagementActivityDetailControl operation={operation} />
            {operation.status === 'done' && totalMutations > 0 && (
                <TabbarTabs
                    onClick={changeView}
                    tabs={[
                        { key: 'detail', title: 'Summary', active: view === 'detail' },
                        { key: 'mutations', title: 'Feed changes', active: view === 'mutations' }
                    ]}
                    className="feed-management-activity-detail__tabs"
                />
            )}
            {view === 'detail' && (
                <FeedManagementActivityDetailSection>
                    {operation.status === 'done' &&
                        Object.entries(operation.data.results).map(([label, count]) => (
                            <FeedManagementActivityDetailSectionRow key={label} status="done" count={count} label={getRowLabel(label, count)} />
                        ))}
                    {operation.status === 'failed' && (
                        <FeedManagementActivityDetailSectionRow status="failed" label={operation.data.results ? operation.data.results.errorMessage : '...'} />
                    )}
                </FeedManagementActivityDetailSection>
            )}
            {view === 'mutations' && <FeedManagementActivityMutations key={operation.id} operationId={operation.id} totalMutations={totalMutations} />}
        </React.Fragment>
    );
};

export default FeedManagementActivityDetail;
