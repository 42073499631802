import React from 'react';
import Status from '../../../ui-components/Status';

import '../styles/detail-section-row.scss';

const FeedManagementActivityDetailSectionRow = ({ status, count, label }) => {
    return (
        <div className="feed-management-activity-detail-section-row">
            <div className="feed-management-activity-detail-section-row__left">
                {count !== undefined && <div className="feed-management-activity-detail-section-row__count">{count}</div>}
                <div className="feed-management-activity-detail-section-row__label">{label}</div>
            </div>
            <Status status={status} />
        </div>
    );
};

export default FeedManagementActivityDetailSectionRow;
